<template>
  <van-row class="selectCity-cont">
    <van-sticky>
      <van-search
        style="max-height:54px;min-height:54px"
        v-model="searchValue"
        show-action
        placeholder="请输入搜索关键词"
        @input="onChange"
        @cancel="onCancel"
      />
    </van-sticky>

    <!-- 平铺的城市列表 长列表优化 -->
    <template v-if="searchValue.length===0">
      <VirtualList
        id="virtualList"
        class="virtual-list"
        :data-key="'id'"
        :data-sources="virtualShowList"
        :data-component="itemComponent"
      />
      <nav :class="'transition select-nav '+(hide=='hide'?'':'hide')">
        <div
          v-for="(navItem,i) in navList"
          :key="i"
          @click="jump(navItem)"
          :class="highlight==i?'blue':''"
        >{{navItem.name}}</div>
      </nav>
    </template>
    <!-- 搜索结果列表 -->
    <template v-if="searchValue.length!==0">
      <van-cell
        v-for="(value,index) in onChange_stationList"
        :key="index"
        style="text-align: start"
        :title="value.name"
        @click="chooseStation(value)"
      />
    </template>
  </van-row>
</template>

<script>
import logic from "../services/commonLogic";
import CHINA_STATION_LIST from "../common/火车站站点list";
import CHINA_AIRCRAFT_LIST from "../common/机场list";
import CHINA_HOTEL_LIST from "../common/国内酒店城市list";
import VirtualList from "vue-virtual-scroll-list";
import SelectCityItem from "./SelectCityItem";
import { off } from "hammerjs";

export default {
  name: "SelectCity",
  mounted() {
    this.queryParam = logic.queryPathDecrypt(this.$route.query);
    this.updataList();
    document
      .getElementById("virtualList")
      .addEventListener("scroll", this.handleScroll); // 监听滚动事件
  },
  components: { VirtualList },
  computed: {
    stationList: function () {
      const type = logic
        .queryPathDecrypt(this.$route.query)
        .function.split("/")[0];
      switch (type) {
        case "aircraft":
          return CHINA_AIRCRAFT_LIST;
        case "train":
          return CHINA_STATION_LIST;
        default:
          return CHINA_HOTEL_LIST;
      }
    },
    // 高亮位置
    highlight: function () {
      let index = -1;
      for (let i = 0; i < this.navList.length; i++) {
        const element = this.navList[i];
        let offset = this.scrollTop / 44;
        if (element.max - 3 > offset && element.position - 3 <= offset) {
          index = i;
          break;
        }
      }
      return index;
    },
  },
  data() {
    return {
      virtualShowList: [], // 虚拟列表数据，有10个左右的空条目用来露出快捷方式
      cleanCol: 0, // 空数行数量
      navList: [], // 导航列表
      hide: "", // 隐藏
      scrollTop: 0, // 滚动位置
      itemComponent: SelectCityItem, // 虚拟列表的组件
      queryParam: {},
      onChange_stationList: {},
      searchValue: "",
      showlist: {},
      loading: false,
      finished: false,
      index: 0,
    };
  },
  methods: {
    // 设置虚拟列表
    updataList() {
      let list = [];
      let hotList = [];
      for (let key in this.stationList) {
        if (key != "hot") {
          list.push({
            id: key,
            name: key,
            islabel: true,
          });
          list.push(...this.stationList[key]);
        } else {
          for (var i = 0; i < this.stationList[key].length; i += 3) {
            hotList.push({
              id: "hot" + i,
              value: this.stationList[key].slice(i, i + 3),
            });
          }
        }
      }
      this.cleanCol = hotList.length;
      this.virtualShowList = [...hotList, ...list];
      this.updataNavList();
    },
    // 生成导航列表
    updataNavList() {
      let list = [];
      let i = this.cleanCol;
      for (let key in this.stationList) {
        if (key != "hot") {
          list.push({
            name: key.toUpperCase(),
            position: i++, // 上边
            max: i + this.stationList[key].length + 1, //下边
          });
          i += this.stationList[key].length;
        }
      }
      this.navList = list;
    },
    // 根据滚动位置开放虚拟列表
    handleScroll() {
      this.scrollTop = document.getElementById("virtualList").scrollTop || 0;
      if (this.scrollTop >= this.cleanCol * 44) {
        this.hide = "hide";
      } else {
        this.hide = "";
      }
    },
    // 右侧快捷方式点击跳转
    jump(nav) {
      document.getElementById("virtualList").scrollTop = nav.position * 44;
    },
    onCancel() {
      this.searchValue = "";
      this.$router.back();
    },
    onChange(val) {
      let temp = [];
      let stationKey = Object.keys(this.stationList);
      for (let i = 1; i < stationKey.length; i++) {
        let theme = this.stationList[stationKey[i]];
        theme.forEach((item) => {
          if (item.name.indexOf(val) >= 0) {
            temp.push(item);
          }
        });
      }
      this.onChange_stationList = temp;
      temp = [];
    },
    chooseStation(element) {
      this.$store.dispatch(this.queryParam.function, element);
      this.$router.back();
      this.onChange_stationList = {};
      this.searchValue = "";
    },
  },
};
</script>

<style lang="less">
.selectCity-cont {
  .van-index-bar__sidebar {
    border-radius: 1rem;
  }
  .van-index-bar__index {
    font-size: 0.9rem;
    padding: 0.1rem 0.5rem 0.1rem 0.7rem;
  }
}
.virtual-list {
  position: relative;
  height: calc(100vh - 54px);
  overflow: auto;
}
.hide {
  opacity: 0;
  z-index: -1;
}
.select-nav {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  div {
    padding: 2px 1rem;
    font-size: 0.9rem;
    text-align: center;
  }
}
#quickSelect {
  position: absolute;
  width: 100%;
  background-color: #fff;
  .quick-item {
    height: 30px;
    padding: 7px 0;
  }
}
</style>

<style lang="less">
#virtualList {
  .van-index-anchor,
  .van-cell {
    min-height: 44px;
    line-height: 44px;
    padding: 0 16px;
  }
}
</style>