<template>
  <div>
    <!-- 快捷方式 -->
    <van-row v-if="!source.hasOwnProperty('name')">
      <van-col span="8" v-for="(data,i) in source.value" :key="i">
        <van-button style="width:100%" type="default" @click="chooseStation(data)">{{data.name}}</van-button>
      </van-col>
    </van-row>
    <!-- 标题 -->
    <van-cell
      style="background:#f5f5f5"
      v-else-if="source.hasOwnProperty('islabel')"
      :title="source.name"
    />
    <!-- 条目 -->
    <van-cell v-else :title="source.name" @click="chooseStation(source)" />
  </div>
</template>

<script>
import logic from "../services/commonLogic";
export default {
  name: "SelectCityItem",
  props: {
    index: {
      type: Number,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    chooseStation(element) {
      this.$store.dispatch(
        logic.queryPathDecrypt(this.$route.query).function,
        element
      );
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.transparent-col {
  background-color: transparent;
  z-index: -10;
  width: 1px;
  height: 44px;
}
</style>